/* Global Styles */
:root {
  --primary-color: #185689;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Body Styles */
body {
  font-family: 'Raleway', sans-serif;
  font-size: .8rem;
  line-height: .8rem;
  min-height: 100vh;
  display: block;
  width: 100%;
  background-color: #ffffff;
  /*border-color: green;
  border-style: solid;
  border-width: 3px;*/
}

h1 {
  font-size: 1.2rem;
}

/* Navbar Styles */
.navbar {
  background: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: auto;
  padding: 0 1rem 0 1rem;
}

.navbartitle {
  color: #ffffff;
  height: auto;
  width: auto;
}

.navbar ul {
  display: flex;
  list-style: none;
}

.navbar li {
  padding: .5rem;
}

.navbartitle h1 {
  font-size: 1rem;
  font-weight: 100;
}

.navbar i {
  padding: .25rem;
}

.navbarmenu {
display: flex;
justify-content: flex-end;
align-items: flex-end;
}

.navbarmenu a {
color: #fff;
}

.navbarmenu a:hover {
color: cyan;
}
  
/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.medium {
  font-size: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Container */
.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 2.4rem;
  background-color: rgb(242, 243, 243);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /*border-color: yellow;
  border-style: solid;
  border-width: 3px;*/
}

.container-login
{
  margin-left: 20%;
  margin-right: 20%;
}

.container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  min-height: 60px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  position: sticky;
  background-color: rgb(250, 250, 250);
  /*border-color: pink;
  border-style: solid;
  border-width: 3px;*/
}

.container-header-light {
  background-color: #ffffff;
}

.container-title
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  min-width: 200px;
  /*border-color: emerald;
  border-style: solid;
  border-width: 3px;*/
}

.container-action
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  width: 100%;
  /*border-color: violet;
  border-style: solid;
  border-width: 3px;*/
}

.container-action h1 {
  margin-left: 0.5rem;
}

.container-header i {
  padding: 0 .25em 0 0;
  /*border-color: orange;
  border-style: solid;
  border-width: 3px;*/
}

.container-header h1 {
  text-align: left;
  padding: 0 0 0 0;
  /*border-color: orange;
  border-style: solid;
  border-width: 3px;*/
}

.container-header ul {
  list-style: none;
  /*border-color: blue;
  border-style: solid;
  border-width: 3px;*/
}

.container-header a:hover {
  color: var(--primary-color);
}

.container-body {
  display: block;
  flex-basis: 0%;
  flex-grow: 1;
  overflow: auto;
  background-color: #ffffff;
  flex-shrink: 1;
  /*border-color: red;
  border-style: solid;
  border-width: 3px;*/
}

/* Filter Up Down */
.filterUpDown
{
  display: inline-block;
  flex-direction: row;
  align-items: bottom;
  justify-content: right;
  width: 100%;
  /*border-color: violet;
  border-style: solid;
  border-width: 3px;*/
}

/* Buttons */
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  border: none;
  cursor: pointer;
  margin: 0.25rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  min-width: 100px;
  justify-content: center;
  text-align: center;
  min-height: 2.2rem;
  /*border-color: palegreen;
  border-style: solid;
  border-width: 3px;*/
}

.btn-rangeUpDown {
  display: inline-block;
  background: var(--primary-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  min-width: 10px;
  justify-content: center;
  text-align: center;
  /*border-color: cyan;
  border-style: solid;
  border-width: 3px;*/
}

.btn-filterUpDown {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  min-width: 10px;
  justify-content: center;
  text-align: center;
  /*border-color: cyan;
  border-style: solid;
  border-width: 3px;*/
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.btn-off {
  opacity: 0.5;

}

.btn-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-transparent {
  background: transparent;
  color: #ffffff;
}

.btn-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

/* Tables */
.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table button{
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 0;
}

.table i {
  margin: 0;
}

.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.table th {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
  background-color: grey;
  color: white;
}

/* Test Results Chart Week */
#testResultsChartWeek {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-color: cyan;
  border-style: solid;
  border-width: 10px;
}

/* Dropbox */
.dropbox {
  display: inline-block;
  margin-left: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  min-width: 100px;
}

/* Alert */
.alert-wrapper {
  top: 2.5rem;
  left: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  height: 30px;
  width: 100%;
  padding: 0 1rem 0 1rem;
  border-color: transparent;
  border-style: solid;
  border-width: 3px;
}

.alert {
  padding: .1rem 1rem;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
  width : 100%;
  border-color: transparent;
  border-style: solid;
  border-width: 3px;
}

.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.alert-light {
  background: var(--light-color);
  color: #333;
}

.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.alert-success {
  background: var(--success-color);
  color: #fff;
}

.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

/* Spinner */
.spinner-wrapper {
  top: 2.5rem;
  left: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-color: transparent;
  border-style: solid;
  border-width: 3px;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .container {
    margin-top: 2rem;
  }

  .hide-sm {
    display: none;
  }

  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }
}

/* Forms */
.form .form-group {
  margin: 0 0 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #ccc;
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}


